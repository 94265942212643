import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import moment from 'moment'
import 'moment/locale/zh-hk'

//import registerServiceWorker from './registerServiceWorker'

ReactDOM.render(<App />, document.getElementById('root'))




//registerServiceWorker();
